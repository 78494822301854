import { httpRasa } from '@app/services/request';
import salesForceService from './SalesForceService';

const getAll = () => {
  // Let's see if it is necessary to obtain full history converstion from RASA
  //return httpRasa.get("/rasa");
  return [];
};

const getTracker = async (user: string) => {
  // Get the tracker of the conversation
  const botResponse = await httpRasa.get(`/rasa/${user}`);
  return botResponse;
};

const create = async (message: string, user: string) => {
  const userMessage = { message, sender: user, isBot: false };
  const botResponse: {
    data: ChatMessages;
  } = await httpRasa.post('/rasa', userMessage);

  let data: ChatMessages = [
    {
      isBot: true,
      message: 'I did not quite understand, could you please rephrase it for me?',
      date: new Date()
    }
  ];
  if (botResponse && botResponse.data && botResponse.data.length) {
    console.info('RASA Response', botResponse.data);

    data = botResponse.data.map(b => ({
      message: b.text ? b.text : "Sorry, I can't get it. Can you please repeat once?",
      isBot: true,
      buttons: b.buttons ? b.buttons : undefined,
      date: new Date()
    }));
  }

  return data;
};

const restart = async () => {
  const restartMessage = { message: '/restart', sender: 'test', isBot: false };
  const botResponse = await httpRasa.post('/rasa', restartMessage);

  if (botResponse && botResponse.status === 200) {
    console.info('RASA restarted conversation OK!');
    return true;
  } else {
    console.log('RASA restarting conversation produced an error', botResponse.statusText);
    return false;
  }
};

const getCurrentIntent = (botResponse: any) => {
  if (botResponse && botResponse.data) {
    const intent = botResponse.data.active_loop ? (botResponse.data.active_loop.name ? botResponse.data.active_loop.name.replace('_form', '') : null) : null;
    return intent;
  } else {
    return null;
  }
};

const checkForSearch = (botResponse: any, intent: string) => {
  if (botResponse && botResponse.data) {
    const slots = botResponse.data.slots;

    const searchTermKey = `${intent}_search_term`;
    const requestedSlot = slots.requested_slot === searchTermKey;

    return requestedSlot;
  } else {
    return false;
  }
};

const checkCachedForm = (botResponse: any) => {
  if (botResponse && botResponse.data) {
    const slots = botResponse.data.slots;

    return slots.cached_form;
  } else {
    return null;
  }
};

// Check if the form has been completed has finished
// This should be handled more gracefully :)
const checkIsFinished = (botResponse: any, currentIntent: any) => {
  if (botResponse && botResponse.data) {
    const slots = botResponse.data.slots;

    return !!currentIntent && !slots.requested_slot && !slots.cached_form;
  } else {
    return false;
  }
};

const search = async (searchTerm: string, intent: string) => {
  try {
    if (searchTerm && intent) {
      const results = await salesForceService.search(intent, searchTerm);
      return results;
    }
    return null;
  } catch (error) {
    console.log('search() - An error occurred while communicating with RASA', error);
    return null;
  }
};

export default {
  getAll,
  getTracker,
  create,
  restart,
  checkForSearch,
  checkCachedForm,
  search,
  getCurrentIntent,
  checkIsFinished
};
