import React, { ReactNode, useMemo } from 'react';
import { Linking } from 'react-native';
import styled, { css } from 'styled-components/native';
import { StyledTextProps, StyledTouchableOpacityProps } from 'styled-components/types';

import { isWeb, trackEvent } from '@common/utils';
import { colors, fonts } from '@common/theme';

export const BUTTON_HEIGHT = 28;

type ButtonProps = {
  children: ReactNode;
  disabled?: boolean;
  onPress?: () => void;
  onPressOut?: () => void;
  trackEventId?: string;
  testID?: string;
  variant?: ButtonVariant;
} & StyledTouchableOpacityProps;

type ButtonWrapProps = {
  background: string;
  border: string;
};

const ButtonWrap = styled.TouchableOpacity.attrs<void, StyledTouchableOpacityProps>({
  activeOpacity: 0.75
})<ButtonWrapProps>(
  ({ background, border, disabled }) => css`
    justify-content: center;
    height: ${BUTTON_HEIGHT}px;
    min-width: 60px;
    padding: 0 12px;
    border-radius: 14px;
    border-width: 1px;
    background: ${background};
    border-color: ${border};
    opacity: ${!disabled ? 1 : 0.75};
  `
);

type ButtonLabelProps = {
  color: string;
  disabled?: boolean;
};

const ButtonLabel = styled.Text.attrs<void, StyledTextProps>({
  numberOfLines: 1
})<ButtonLabelProps>(
  ({ color, disabled }) => css`
    text-align: center;
    font-family: ${fonts.medium};
    font-size: 14px;
    color: ${color};
    opacity: ${!disabled ? 1 : 0.9};
  `
);

const getColor = (variant: ButtonVariant): { label: string; background: string; border: string } => {
  if (variant === 'grey') {
    return {
      label: colors.grey4,
      background: colors.grey6,
      border: colors.grey6
    };
  }

  if (variant === 'light') {
    return {
      label: colors.grey4,
      background: colors.white,
      border: colors.primary
    };
  }

  return {
    label: colors.white,
    background: colors.primary,
    border: colors.primary
  };
};

const linkRegex = /<a.*href="(.*?)".*>/;

const Button = ({ children, disabled, onPress, onPressOut, trackEventId, testID, variant = 'primary', ...props }: ButtonProps) => {
  const color = useMemo(() => getColor(variant), [variant]);

  const label = useMemo(() => {
    if (typeof children === 'string') {
      return children.replace(linkRegex, '');
    }

    return children;
  }, [children]);

  const onButtonPress = () => {
    if (onPress) {
      trackEvent(trackEventId, {
        action: testID
      });
      onPress();
    }
    if (typeof children === 'string') {
      const match = children.match(linkRegex);
      if (match) {
        if (!isWeb) {
          Linking.openURL(match[1]);
        } else {
          window.open(
            match[1],
            match[1].slice(0, 4) === 'tel:' ? '_self' : '_blank' // self only if is a call.
          );
        }
      }
    }
  };

  const onButtonPressOut = () => {
    if (onPressOut) {
      trackEvent(trackEventId);
      onPressOut();
    }
  };

  return (
    <ButtonWrap
      {...props}
      testID={testID}
      accessibilityRole="button"
      background={color.background}
      disabled={disabled}
      onPress={onButtonPress}
      onPressOut={onButtonPressOut}
      border={color.border}
    >
      <ButtonLabel color={color.label} disabled={disabled}>
        {label}
      </ButtonLabel>
    </ButtonWrap>
  );
};

export default Button;
