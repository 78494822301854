import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';

import { ProfileScreen } from '@app/layouts';
import { PROFILE_SCREEN } from '@app/navigation/routes';

const Stack = createStackNavigator();

const ProfileStack = () => (
  <Stack.Navigator initialRouteName={PROFILE_SCREEN} screenOptions={{ headerShown: false }}>
    <Stack.Screen name={PROFILE_SCREEN} component={ProfileScreen} />
  </Stack.Navigator>
);

export default ProfileStack;
