import init from '@elastic/apm-rum';

import { apmServerURL, authUrl, notificationUrl, coreUrl, env, isWeb, isChromeExtension, isSalesforceWebTab, apmSecretToken } from '@common/utils';
import URL from '@common/utils/url';

const serviceName = () => {
  if (isChromeExtension) {
    return `rollio-web-app-chrome-${env}`;
  }

  if (isSalesforceWebTab) {
    return `rollio-web-app-salesforceWebTab-${env}`;
  }

  if (isWeb) {
    return `rollio-web-app-${env}`;
  }

  return `rollio-mobile-app-${env}`;
};

const distributedTracingOrigins = [authUrl, notificationUrl, coreUrl].map(url => {
  const parsedURL = new URL(url);

  return parsedURL.origin;
});

export const startApm = () => {
  console.log('APM instrumentation on');
  console.log('==========', {
    environment: env,
    serviceName: serviceName(),
    serverUrl: apmServerURL,
    // secretToken: apmSecretToken,
    distributedTracingOrigins
  });
  init({
    environment: env,
    serviceName: serviceName(),
    serverUrl: apmServerURL,
    // @ts-ignore
    secretToken: apmSecretToken,
    distributedTracingOrigins
  });
};
