import React, { Ref } from 'react';
import styled, { css } from 'styled-components/native';
import { StyledTextInput, StyledTextInputProps } from 'styled-components/types';

import { isWeb } from '@common/utils';
import { colors, fonts } from '@common/theme';
import { Icon } from '@common/components';

type TextInputProps = {
  icon?: string;
  iconColor?: string;
  inputRef?: Ref<StyledTextInput>;
  keyboardType?: string;
  loading?: boolean;
  onChange: (value: string) => void;
  onIconPress?: () => void;
  onSubmitEditing?: () => void;
  placeholder?: string;
  returnKeyType?: string;
  secureTextEntry?: boolean;
  textContentType?: string;
  value: string;
} & Omit<StyledTextInputProps, 'onChange'>;

const InputWrap = styled.View`
  width: 100%;
  flex-direction: row;
  border-bottom-width: 1px;
  border-bottom-color: rgba(112, 112, 112, 0.2);
`;

const Input = styled.TextInput`
  font-family: ${fonts.regular};
  color: rgba(88, 89, 91, 0.7);
  padding: 5px;
  font-size: ${!isWeb ? 14 : 16}px;
  flex: 1;

  ${isWeb &&
  css`
    outline-width: 0;
  `}
`;

const Spinner = styled.ActivityIndicator.attrs({ color: colors.primary })`
  margin-left: 5px;
`;

const InputIcon = styled(Icon)`
  align-self: center;
  padding: 2px 5px;
`;

const TextInput = ({
  icon,
  iconColor = '',
  inputRef,
  keyboardType,
  loading = false,
  onChange,
  onIconPress = () => {},
  onSubmitEditing = () => {},
  placeholder = '',
  returnKeyType,
  secureTextEntry = false,
  textContentType,
  value,
  ...props
}: TextInputProps) => (
  <InputWrap>
    <Input
      {...props}
      autoCapitalize="none"
      autoCorrect={false}
      spellCheck={false}
      keyboardType={keyboardType}
      onChangeText={onChange}
      onSubmitEditing={onSubmitEditing}
      placeholder={placeholder}
      placeholderTextColor="rgba(88,89,91,0.7)"
      ref={inputRef}
      returnKeyType={returnKeyType}
      secureTextEntry={secureTextEntry}
      textContentType={textContentType}
      underlineColorAndroid="transparent"
      value={value}
    />

    {loading && <Spinner />}

    {icon && <InputIcon color={iconColor} name={icon} onPress={onIconPress} size={20} />}
  </InputWrap>
);

export default TextInput;
