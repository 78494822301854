import React, { Dispatch, SetStateAction, useMemo, useRef } from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import styled from 'styled-components/native';

import { colors, fonts } from '@common/theme';
import { FONT_SIZE, INPUT_LINE_HEIGHT, MAX_INPUT_HEIGHT, MINIMIZED_HEIGHT, PLACEHOLDER_COLOR } from './shared';

export type ScrollViewProps = {
  placeholder: string;
  setHeight: Dispatch<SetStateAction<number>>;
  value: string;
  height: number;
};

const ScrollViewText = styled.Text`
  font-size: ${FONT_SIZE}px;
  color: ${colors.grey4};
  min-height: ${MINIMIZED_HEIGHT}px;
  line-height: ${INPUT_LINE_HEIGHT}px;
`;

const ScrollViewStyled = styled.ScrollView`
  margin: 5px 12px 5px 18px;
  flex: 1;
`;

const PlaceholderText = styled.Text`
  font-size: ${FONT_SIZE}px;
  color: ${PLACEHOLDER_COLOR};
  padding: 0;
  margin: 0 0 6px 0;
  font-family: ${fonts.regular};
  line-height: ${INPUT_LINE_HEIGHT}px;
`;

const ScrollComponent = (props: ScrollViewProps) => {
  const { height, value, placeholder, setHeight } = props;
  const scrollViewRef = useRef(null);
  const isExpanded = height > MINIMIZED_HEIGHT;
  const styles = useMemo(() => {
    return {
      alignSelf: isExpanded ? 'flex-start' : 'flex-end',
      height: isExpanded ? height : MINIMIZED_HEIGHT,
      maxHeight: MAX_INPUT_HEIGHT,
      marginTop: isExpanded ? 15 : 5
    } as StyleProp<ViewStyle>;
  }, [isExpanded, height]);

  const scrollToBottom = () => {
    if (scrollViewRef.current) {
      // @ts-ignore
      scrollViewRef.current.scrollToEnd({ animated: false });
    }
  };

  const handleScrollViewContentHeight = (_: number, scrollHeight: number) => {
    if (height !== scrollHeight) {
      setHeight(scrollHeight);
      scrollToBottom();
    }
  };

  return (
    <ScrollViewStyled ref={scrollViewRef} style={styles} scrollEventThrottle={400} onContentSizeChange={handleScrollViewContentHeight}>
      {value ? <ScrollViewText selectable>{value}</ScrollViewText> : <PlaceholderText>{placeholder}</PlaceholderText>}
    </ScrollViewStyled>
  );
};

export default ScrollComponent;
