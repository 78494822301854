import React, { useMemo } from 'react';
import { BottomTabBarProps } from '@react-navigation/bottom-tabs';

import { useDispatch, useSelector } from '@common/hooks';
import { scrollChatToBottom, toggleIsTabBarMinimized, getIsTabBarMinimized } from '@common/store/reducers/ui';
import { TabBar } from '@common/components';
import { getCurrentRouteName } from '@common/utils';
import { WALKTHROUGH_SCREEN } from '@common/navigation/routes';
import { getNavState } from '@common/navigation/utils';
import { CHAT_TAB, CHAT_SCREEN, PROFILE_TAB, NOTIFICATIONS_TAB } from '@app/navigation/routes';

const tabIcons = {
  [CHAT_TAB]: 'chat-bubble',
  [NOTIFICATIONS_TAB]: 'notifications',
  [PROFILE_TAB]: 'account-circle'
};

const ConnectedTabBar = (props: BottomTabBarProps) => {
  const dispatch = useDispatch();
  const isTabBarMinimized = useSelector(getIsTabBarMinimized);
  // @ts-ignore
  const currentRouteName = getCurrentRouteName(getNavState());
  const onHandlePress = () => dispatch(toggleIsTabBarMinimized());

  const shouldHideTabs = useMemo(() => !!currentRouteName && [WALKTHROUGH_SCREEN].includes(currentRouteName), [currentRouteName]);
  const onHandleTabPress = (routeName: string) => {
    if (currentRouteName === CHAT_SCREEN && routeName === CHAT_TAB) {
      dispatch(scrollChatToBottom());
    }
  };

  return (
    <TabBar
      {...props}
      isMinimized={isTabBarMinimized}
      onHandlePress={onHandlePress}
      shouldHideTabs={shouldHideTabs}
      tabIcons={tabIcons}
      onHandleTabPress={onHandleTabPress}
    />
  );
};

export default ConnectedTabBar;
