import React from 'react';

import { LoadingOverlay, ScreenWrap } from '@common/components';
import { Chat } from '@app/components';
import { ChatStackScreenProps } from '@app/navigation/types';
import { CHAT_SCREEN } from '@app/navigation/routes';

type ChatScreenProps = ChatStackScreenProps<typeof CHAT_SCREEN>;

const ChatScreen = (_props: ChatScreenProps) => {
  const isLoading = false;

  return (
    <>
      <ScreenWrap>
        <Chat />
      </ScreenWrap>
      <LoadingOverlay id="chat" visible={isLoading} showBackdrop={false} />
    </>
  );
};

export default ChatScreen;
