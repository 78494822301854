import { FlattenSimpleInterpolation } from 'styled-components';
import styled, { css } from 'styled-components/native';
import { StyledTextProps } from 'styled-components/types';

import { includes } from '@common/utils';
import { colors, fonts } from '@common/theme';

type Variant = 'label' | 'value';
const variants: Array<Variant> = ['label', 'value'];

type VariantProps = {
  color: string;
  size: string;
  family: string;
  additional?: FlattenSimpleInterpolation;
};

const TEXT_VARIANTS: { [K in Variant]: VariantProps } = {
  label: {
    color: colors.primary,
    size: '11px',
    family: fonts.regular,
    additional: css`
      padding-right: 4px;
    `
  },
  value: {
    color: colors.grey4,
    size: '13px',
    family: fonts.medium
  }
};

type SegmentTextProps = {
  variant: Variant;
};

const SegmentText = styled.Text.attrs<void, StyledTextProps>({
  numberOfLines: 1
})<SegmentTextProps>(({ variant }) => {
  if (includes(variants, variant)) {
    const { color, size, family, additional } = TEXT_VARIANTS[variant];

    return css`
      max-width: 110px;
      color: ${color};
      font-size: ${size};
      font-family: ${family};

      ${additional && additional};
    `;
  }

  return css`
    max-width: 110px;
  `;
});

export default SegmentText;
