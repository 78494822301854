import React from 'react';
import { Animated } from 'react-native';
import styled from 'styled-components/native';

import { colors } from '@common/theme';
import { isIphoneX, times } from '@common/utils';

type PaginationProps = {
  activePage: Animated.AnimatedDivision;
  dotCount: number;
};

const PaginationWrap = styled.View`
  position: absolute;
  left: 0;
  right: 0;
  top: ${isIphoneX ? '526px' : '446px'};
  flex-direction: row;
  justify-content: center;
`;

const PaginationDot = styled.View`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: ${colors.grey4};
  margin: 4px;
`;

const Pagination = ({ activePage, dotCount }: PaginationProps) => (
  <PaginationWrap pointerEvents="none">
    {times(
      i => (
        <PaginationDot
          key={i}
          as={Animated.View}
          style={{
            opacity: activePage.interpolate({
              inputRange: [i - 1, i, i + 1],
              outputRange: [0.3, 1, 0.3],
              extrapolate: 'clamp'
            }),
            transform: [
              {
                scale: activePage.interpolate({
                  inputRange: [i - 1, i, i + 1],
                  outputRange: [0.8, 1, 0.8],
                  extrapolate: 'clamp'
                })
              }
            ]
          }}
        />
      ),
      dotCount
    )}
  </PaginationWrap>
);

export default Pagination;
