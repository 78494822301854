import React, { useCallback } from 'react';
import styled from 'styled-components/native';

import { useDispatch, useSelector } from '@common/hooks';
import { logout } from '@common/store/reducers/auth';
import { getUser } from '@common/store/reducers/user';
import { isWeb, statusBarHeight } from '@common/utils';
import { colors } from '@common/theme';
import { Footer, ScreenWrap, AvatarHeader, HelpSection, CONTACT_LINK, WALKTHROUGH_LINK, ABOUT_LINK, PRIVACY_LINK, TERMS_LINK } from '@common/components';
import { WALKTHROUGH_SCREEN, WEBVIEW_SCREEN } from '@common/navigation/routes';
import { ProfileStackScreenProps } from '@app/navigation/types';
import { PROFILE_SCREEN } from '@app/navigation/routes';

type Links = typeof CONTACT_LINK | typeof WALKTHROUGH_LINK | typeof ABOUT_LINK | typeof PRIVACY_LINK | typeof TERMS_LINK;

const links = {
  [CONTACT_LINK]: {
    header: 'Contact Us',
    url: 'https://support-desk.rollio.ai'
  },
  [ABOUT_LINK]: {
    header: 'About Us',
    url: 'https://www.rollio.ai/'
  },
  [PRIVACY_LINK]: {
    header: 'Privacy Policy',
    url: 'https://www.rollio.ai/privacy-policy'
  },
  [TERMS_LINK]: {
    header: 'Terms',
    url: 'https://www.rollio.ai/terms-of-use/'
  },
  [WALKTHROUGH_LINK]: {
    header: '',
    url: ''
  }
};

const ProfileScreenWrap = styled(ScreenWrap)`
  padding-top: ${statusBarHeight}px;
  background: ${colors.primary};
`;

const ContentWrap = styled(ScreenWrap)`
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;

type ProfileScreenProps = ProfileStackScreenProps<typeof PROFILE_SCREEN>;

const ProfileScreen = ({ navigation }: ProfileScreenProps) => {
  const dispatch = useDispatch();
  const { email, name, userImage, userInitials } = useSelector(getUser);

  const userLogout = () => {
    dispatch(logout());
  };

  const navigateToUrl = useCallback(
    (link: string) => {
      if (link === WALKTHROUGH_LINK) {
        navigation.navigate(WALKTHROUGH_SCREEN, { showSkipButton: false });
        return;
      }

      const index = link as Links;
      const { header, url } = links[index];

      if (header && url) {
        if (!isWeb) {
          navigation.navigate(WEBVIEW_SCREEN, { header, url });
        } else {
          window.open(url, '_blank');
        }
      }
    },
    [navigation]
  );

  return (
    <ProfileScreenWrap>
      <AvatarHeader image={userImage} onLogoutPress={userLogout} initials={userInitials} name={name} email={email} />

      <ContentWrap>
        <HelpSection onUrlPress={navigateToUrl} />
        <Footer onUrlPress={navigateToUrl} />
      </ContentWrap>
    </ProfileScreenWrap>
  );
};

export default ProfileScreen;
