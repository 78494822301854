import axios from 'axios';

import { appCoreUrl } from '@common/utils';
import store from '@common/store';

const baseURL = `${appCoreUrl}/api`;

const httpRasa = axios.create({
  baseURL,
  headers: {
    'Content-type': 'application/json'
  }
});

const httpWatson = axios.create({
  baseURL,
  headers: {
    'Content-type': 'application/json'
  }
});

const httpSF = axios.create({
  baseURL,
  headers: {
    'Content-type': 'application/json'
  }
});

httpSF.interceptors.request.use(request => {
  const state = store.getState();
  // @ts-ignore
  request.headers.common.Authorization = `Bearer ${state.auth.token}`;

  return request;
});

export { httpRasa, httpWatson, httpSF };
