import React, { useMemo } from 'react';
import { FlatList } from 'react-native';
import styled from 'styled-components/native';

import { isWeb } from '@common/utils';

import Message from './Message';
import FollowUpMessage from './FollowUpMessage';

const MessagesSection = styled.FlatList`
  margin: auto;
  width: 100%;
  height: auto;
  transition: 0.3s;
  border-radius: 20px;
  padding-bottom: 10px;
` as unknown as typeof FlatList;

const MessagesContainer = styled.View`
  flex-directin: column;
  padding: 0 10px;
`;

type MessagesProps = {
  messages: ChatMessages;
  followUpActions: any;
  showFollowUp: boolean;
  onClick: (message: string) => void;
  loadingStatus: boolean;
  showPanel: boolean;
};

type ListItem = {
  key: number;
  type: string;
  data?: any;
};

type ListData = ListItem[];

const loadingMessage = { isBot: true, message: '', date: new Date() };

const Messages = ({ messages, followUpActions, showFollowUp, onClick, loadingStatus, showPanel }: MessagesProps) => {
  // const listRef = useRef<any>();
  const data = useMemo(() => {
    const listData: ListData = [...messages].map((message, i) => {
      const isLatest = messages.length === i + 1 && !loadingStatus;

      return {
        key: i,
        type: 'message',
        data: {
          message,
          isLatest
        }
      };
    });

    if (loadingStatus) {
      listData.push({
        key: listData.length,
        type: 'loading'
      });
    }

    if (showFollowUp && followUpActions) {
      listData.push({
        key: listData.length + 1,
        type: 'actions'
      });
    }
    return listData.reverse();
  }, [messages, loadingStatus, showFollowUp, followUpActions]);

  // useEffect(() => {
  //   if (listRef && listRef.current) {
  //     listRef.current.getScrollResponder().scrollTo({
  //       x: 0,
  //       y: 0,
  //       animated: true,
  //     });
  //   }
  // }, [data.length]);

  return (
    <MessagesSection<ListItem>
      style={{ paddingTop: !showPanel ? 70 : 20 }}
      inverted
      disableVirtualization={isWeb}
      viewabilityConfig={{
        itemVisiblePercentThreshold: 5
      }}
      data={data}
      // ref={listRef}
      renderItem={({ item: row }) => {
        return (
          <MessagesContainer key={row.key}>
            {row.type === 'message' && <Message message={row.data.message} isLatest={row.data.isLatest} />}
            {row.type === 'loading' && <Message message={loadingMessage} isLatest />}
            {row.type === 'actions' && <FollowUpMessage followUpActions={followUpActions} onClick={onClick} loadingStatus={loadingStatus} />}
          </MessagesContainer>
        );
      }}
    />
  );
};

export default Messages;
