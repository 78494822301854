import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import qs from 'query-string';

import { isChromeExtension, pathOr } from '@common/utils';
import { useDispatch } from '@common/hooks';
import { logout } from '@common/store/reducers/auth';
import { reloadNotifications } from '@common/store/reducers/notifications';
import store, { appContext } from '@app/store';
import { Navigator } from '@app/navigation';

const persistor = persistStore(store);

const Launcher = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const handleWebMessageEvent = ({ data = {} }) => {
      const type = pathOr<string>('', ['type'], data);

      if (type === 'ROLLIO/LOGOUT') {
        dispatch(logout());
      }

      if (type === 'ROLLIO/GET_NOTIFICATIONS') {
        dispatch(reloadNotifications());
      }

      if (type === 'ROLLIO/ADD_MESSAGES') {
        //TODO: set messages
      }
    };

    if (isChromeExtension) {
      // Get initial messages from URL parameter
      const parsedUrl = qs.parseUrl(window.location.href);
      const urlData = pathOr('{}', ['query', 'data'], parsedUrl);
      const parsedUrlData = JSON.parse(urlData);
      const injectedMessages = pathOr([], ['messages'], parsedUrlData);

      if (injectedMessages && injectedMessages.length) {
        //TODO: set messages
      }

      // Add native `message` event listener
      window.addEventListener('message', handleWebMessageEvent, false);
    }

    return () => {
      if (isChromeExtension) {
        window.removeEventListener('message', handleWebMessageEvent, false);
      }
    };
  }, []);

  return <Navigator />;
};

const App = () => {
  return (
    <Provider store={store} context={appContext}>
      <PersistGate persistor={persistor}>
        <Launcher />
      </PersistGate>
    </Provider>
  );
};

export default App;
