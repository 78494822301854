import React, { useMemo } from 'react';
import styled, { css } from 'styled-components/native';
import { StyledTouchableOpacityProps } from 'styled-components/types';

import { colors, fonts } from '@common/theme';
import { prettyDate } from '@common/utils';
import { TouchableWithEvent } from '@common/components';
import { useSelector } from '@common/hooks';
import { getTimeFormat } from '@common/store/reducers/user';

type VariantEnum = 'default' | 'shortcut';

type NotificationProps = {
  body: string;
  date: Date;
  isLast?: boolean;
  isRead: boolean;
  onPress: () => void;
  title: string;
  variant?: VariantEnum;
};

type TouchableRowProps = {
  isLast: boolean;
  variant: VariantEnum;
};

const TouchableRow = styled.TouchableOpacity.attrs<void, StyledTouchableOpacityProps>({
  activeOpacity: 0.75
})<TouchableRowProps>(({ isLast, variant }) =>
  variant === 'shortcut'
    ? css`
        background: ${colors.grey6};
        padding: 7px;
        margin-bottom: ${!isLast ? '8px' : 0};
        border-radius: 6px;
      `
    : css`
        background: ${colors.white};
        padding: 18px 10px;
      `
);

type HeaderProps = {
  marginBottom: string;
};

const Header = styled.View<HeaderProps>(
  ({ marginBottom }) => css`
    flex-direction: row;
    margin-bottom: ${marginBottom};
    align-items: flex-start;
  `
);

type TitleProps = {
  color: string;
};

const Title = styled.Text<TitleProps>(
  ({ color }) => css`
    flex: 1;
    padding-right: 18px;
    font-size: 15px;
    font-family: ${fonts.medium};
    color: ${color};
  `
);

type DateLabelProps = {
  color: string;
};

const DateLabel = styled.Text<DateLabelProps>(
  ({ color }) => css`
    font-size: 13px;
    font-family: ${fonts.regular};
    color: ${color};
  `
);

const BodyWrap = styled.View`
  flex-direction: row;
  align-items: center;
`;

const Body = styled.Text`
  width: 90%;
  font-size: 13px;
  font-family: ${fonts.regular};
  color: ${colors.grey3};
`;

const UnreadMarker = styled.View`
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background: ${colors.primary};
  margin-left: auto;
`;

const shortcutBodyProps = {
  numberOfLines: 2
};

const Notification = ({ body = '', date, isLast = false, isRead = true, onPress, title = '', variant = 'default' }: NotificationProps) => {
  const timeFormat = useSelector(getTimeFormat);

  const isShortcut = variant === 'shortcut';

  const headerMarginBottom = isShortcut ? '5px' : '10px';
  const titleColor = isShortcut ? colors.grey4 : colors.grey3;
  const dateColor = isShortcut ? colors.grey8 : colors.grey2;

  const formattedDate = useMemo(() => prettyDate(date, timeFormat), [date, timeFormat]);
  const bodyProps = isShortcut ? shortcutBodyProps : {};
  const trackEventId = isShortcut ? 'home-notification-click' : 'notifications-item-click';

  return (
    <TouchableRow variant={variant} isLast={isLast} onPress={onPress} as={TouchableWithEvent} trackEventId={trackEventId}>
      <Header marginBottom={headerMarginBottom}>
        <Title color={titleColor} accessibilityRole="header" aria-level="3">
          {title}
        </Title>
        <DateLabel color={dateColor}>{formattedDate}</DateLabel>
      </Header>
      <BodyWrap>
        <Body {...bodyProps}>{body}</Body>
        {!isRead && <UnreadMarker />}
      </BodyWrap>
    </TouchableRow>
  );
};

export default Notification;
