import React, { ReactNode, useMemo } from 'react';
import { TouchableOpacityProps } from 'react-native';
import styled, { css } from 'styled-components/native';
import { StyledTouchableOpacityProps } from 'styled-components/types';
import { rgba } from 'polished';

import { colors, fonts } from '@common/theme';
import { Icon } from '@common/components';

import TabBadge from './TabBadge';

type TabButtonProps = {
  badgeLabel?: number;
  disabled?: boolean;
  isActive?: boolean;
  icon: string;
  label: string | ReactNode;
  onPress?: () => void;
  showBadge?: boolean;
} & TouchableOpacityProps;

const TabButtonWrap = styled.TouchableOpacity.attrs<void, StyledTouchableOpacityProps>({
  activeOpacity: 0.8
})`
  align-items: center;
  padding: 0 16px;
`;

const IconWrap = styled.View``;

type LabelProps = {
  isActive?: boolean;
};

const Label = styled.Text<LabelProps>(
  ({ isActive }) => css`
    font-family: ${fonts.medium};
    font-size: 10px;
    margin-top: 3px;
    color: ${isActive ? colors.primary : rgba(colors.grey4, 0.6)};
  `
);

const TabButton = ({ badgeLabel, disabled, isActive, icon, label, onPress, showBadge, ...props }: TabButtonProps) => {
  const iconColor = useMemo(() => colors[isActive ? 'primary' : 'grey9'], [isActive]);

  return (
    <TabButtonWrap {...props} disabled={disabled} onPress={onPress} accessibilityRole="button">
      <IconWrap>
        <Icon name={icon} size={30} color={iconColor} />
        <TabBadge isVisible={showBadge} value={badgeLabel} />
      </IconWrap>
      <Label isActive={isActive}>{label}</Label>
    </TabButtonWrap>
  );
};

export default TabButton;
