import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';

import { ChatScreen } from '@app/layouts';
import { CHAT_SCREEN } from '@app/navigation/routes';

const Stack = createStackNavigator();

const ChatStack = () => (
  <Stack.Navigator initialRouteName={CHAT_SCREEN} screenOptions={{ headerShown: false }}>
    <Stack.Screen name={CHAT_SCREEN} component={ChatScreen} />
  </Stack.Navigator>
);

export default ChatStack;
