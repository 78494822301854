import React from 'react';
import styled from 'styled-components/native';

import { Button } from '@common/components';

type FollowUpMessageProps = {
  followUpActions: FollowUpActions;
  loadingStatus: boolean;
  onClick: (message: string) => void;
};

const ButtonContainer = styled.View`
  flex-direction: row;
  margin: 10px 0;
`;

const StyledButton = styled(Button)`
  margin-right: 10px;
`;

const FollowUpMessage = ({ followUpActions, loadingStatus, onClick }: FollowUpMessageProps) => {
  return (
    <ButtonContainer>
      {followUpActions &&
        followUpActions.map(
          (fu, idx) =>
            !fu.current && (
              <StyledButton key={idx} onPress={() => onClick(fu.name)} disabled={loadingStatus} variant="light">
                {fu.name}
              </StyledButton>
            )
        )}
    </ButtonContainer>
  );
};

export default FollowUpMessage;
