import React from 'react';

import { EmptyState, TableTypeC } from '@common/components';

type SearchPanelProps = {
  data: SearchResults;
  onClick: (selection: any) => void;
  onRefineSearch: () => void;
};

const SearchPanel = ({ data, onClick, onRefineSearch }: SearchPanelProps) => {
  const tableRows = (data || []).map(result => {
    const metaData = result.columns.reduce((acc: any, column) => {
      acc[column.name] = column.displayValue;
      return acc;
    }, {});
    const tableData = result.columns.map(column => ({
      name: column.header,
      value: column.displayValue
    }));
    return {
      id: `${result.value}|${result.displayValue}`,
      metaData,
      tableData,
      postback: {
        fields: []
      },
      isSelected: !!result?.isSelected
    };
  });

  return (
    <>
      {data && data.length ? (
        <TableTypeC
          tableRows={tableRows}
          onRowPress={(rows: any, rowIndex: number) => {
            const row = rows[rowIndex];
            const [key, value] = row.id.split('|');
            const selection = { key, value };
            onClick(selection);
          }}
        />
      ) : (
        <EmptyState onButtonPress={onRefineSearch} displayButton={false} />
      )}
    </>
  );
};

export default SearchPanel;
