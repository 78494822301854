import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '@app/store';

interface ChatState {
  responses: ChatMessages;
  checkForSearch: boolean;
  searchResults: SearchResults;
  currentMessage: string;
  loading: boolean;
  currentIntent: string | null;
  accountId: string | null;
  optionButtons: OptionButtons;
  showActions: boolean;
  followUpActions: FollowUpActions;
  showSearchPanel: boolean;
}

const initialFollowUpActions = [
  { name: 'Create Opportunity', current: false },
  { name: 'Update Task', current: false }
];

const initialState: ChatState = {
  responses: [],
  checkForSearch: false,
  searchResults: [],
  currentMessage: '',
  loading: false,
  currentIntent: null,
  accountId: null,
  optionButtons: [],
  showActions: true,
  followUpActions: initialFollowUpActions,
  showSearchPanel: false
};

// Slice
export const chat = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setResponses: (state, action) => {
      state.responses = action.payload;
    },
    setCheckForSearch: (state, action) => {
      state.checkForSearch = action.payload;
    },
    setSearchResults: (state, action) => {
      state.searchResults = action.payload;
    },
    setCurrentMessage: (state, action) => {
      state.currentMessage = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setCurrentIntent: (state, action) => {
      state.currentIntent = action.payload;
    },
    setAccountId: (state, action) => {
      state.accountId = action.payload;
    },
    setOptionButtons: (state, action) => {
      state.optionButtons = action.payload;
    },
    setShowActions: (state, action) => {
      state.showActions = action.payload;
    },
    setFollowUpActions: (state, action) => {
      state.followUpActions = action.payload;
    },
    setShowSearchPanel: (state, action) => {
      state.showSearchPanel = action.payload;
    },
    reset: () => initialState
  }
});

// Actions
export const {
  setResponses,
  setCheckForSearch,
  setSearchResults,
  setCurrentMessage,
  setLoading,
  setCurrentIntent,
  setAccountId,
  setOptionButtons,
  setShowActions,
  setFollowUpActions,
  setShowSearchPanel,
  reset
} = chat.actions;

// Selectors
export const selectChat = (state: RootState) => state.chat;

export default chat.reducer;
