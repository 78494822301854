import { useRef, useEffect } from 'react';
import { Animated } from 'react-native';
import { useKeyboard } from '@react-native-community/hooks';

import { useSelector } from '@common/hooks';
import { boolToNum, isAndroid } from '@common/utils';
import { TAB_BAR_HEIGHT, TAB_BAR_MINIMIZED_HEIGHT } from '@common/components';
import { getIsTabBarMinimized } from '@common/store/reducers/ui';

type UseTabBarHeight = () => {
  animatedTabBarHeight: Animated.AnimatedInterpolation | number;
  tabBarHeight: number;
};

const useTabBarHeight: UseTabBarHeight = () => {
  const isTabBarMinimized = useSelector(getIsTabBarMinimized);
  const animatedTabBarHeight = useRef(new Animated.Value(boolToNum(isTabBarMinimized))).current;

  useEffect(() => {
    Animated.timing(animatedTabBarHeight, {
      toValue: boolToNum(isTabBarMinimized),
      duration: 300,
      useNativeDriver: false
    }).start();
  }, [isTabBarMinimized, animatedTabBarHeight]);

  const { keyboardShown } = useKeyboard();
  if (keyboardShown && isAndroid) {
    return { animatedTabBarHeight: 0, tabBarHeight: 0 };
  }

  const tabBarHeight = !isTabBarMinimized ? TAB_BAR_HEIGHT : TAB_BAR_MINIMIZED_HEIGHT;
  return {
    animatedTabBarHeight: animatedTabBarHeight.interpolate({
      inputRange: [0, 1],
      outputRange: [TAB_BAR_HEIGHT, TAB_BAR_MINIMIZED_HEIGHT]
    }),
    tabBarHeight
  };
};

export default useTabBarHeight;
