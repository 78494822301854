import React from 'react';

import { FlatList, Header, LoadingOverlay, EmptyState, ScreenWrap, Notification } from '@common/components';
import { useDispatch, useSelector, useTabBarHeight } from '@common/hooks';
import { getNotifications, loadNotifications, reloadNotifications, selectNotification } from '@common/store/reducers/notifications';
import { NotificationsStackScreenProps } from '@app/navigation/types';
import { NOTIFICATIONS_SCREEN } from '@app/navigation/routes';

type NotificationsScreenProps = NotificationsStackScreenProps<typeof NOTIFICATIONS_SCREEN>;

const NotificationsScreen = (_props: NotificationsScreenProps) => {
  const dispatch = useDispatch();
  const { areMore, isLoading, isProcessingNotification, notifications } = useSelector(getNotifications);

  const loadMoreNotifications = () => dispatch(loadNotifications());

  const onNotificationPress = (notification: NotificationShape) => dispatch(selectNotification(notification.id));

  const refreshNotifications = () => dispatch(reloadNotifications());

  const { animatedTabBarHeight } = useTabBarHeight();
  const hasNotifications = notifications.length > 0;
  const isInitialLoading = !hasNotifications && isLoading;

  return (
    <ScreenWrap>
      <Header center="Notifications" />

      {(hasNotifications || isInitialLoading) && (
        <FlatList
          areMoreItems={areMore}
          data={notifications}
          isLoading={isLoading}
          onLoadMore={loadMoreNotifications}
          onRefresh={refreshNotifications}
          renderItem={({ item }) => (
            <Notification
              title={item.title}
              date={item.date}
              onPress={() => onNotificationPress(item as NotificationShape)}
              isRead={item.isRead}
              body={item.body}
            />
          )}
        />
      )}

      {!hasNotifications && !isLoading && <EmptyState title="All clear! 👏" displayButton={false} style={{ paddingBottom: animatedTabBarHeight }} />}

      <LoadingOverlay visible={isProcessingNotification} />
    </ScreenWrap>
  );
};

export default NotificationsScreen;
