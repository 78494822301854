import AsyncStorage from '@react-native-async-storage/async-storage';
import { AnyAction, combineReducers, configureStore } from '@reduxjs/toolkit';
import { createContext } from 'react';
import { ReactReduxContextValue } from 'react-redux';
import { persistReducer } from 'redux-persist';

import { chat } from './reducers';

const persistConfig = {
  key: 'app',
  version: 1,
  storage: AsyncStorage
};

const rootReducer = combineReducers({
  chat
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const appContext = createContext({} as ReactReduxContextValue<any, AnyAction>);

export default store;
