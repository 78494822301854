import { httpSF } from '@app/services/request';
import ChatService from './ChatService';

const search = async (intent: string, searchTerm: string) => {
  try {
    const sfResponse = await httpSF.post('/sf/search', {
      intent,
      searchTerm
    });

    if (sfResponse && sfResponse.data) {
      return sfResponse.data;
    }
  } catch (error) {
    console.log('search() - An error has occurred: ', error);
    return [];
  }
};

const save = async (user: string, intent: string, extraData: any) => {
  try {
    // Obtain conversation tracker to check which SF object we should create
    const tracker = await ChatService.getTracker(user);

    const data = tracker.data.slots;
    data.account_id = extraData.accountId;

    const sfResponse = await httpSF.post('/sf/save', {
      intent,
      data
    });

    if (sfResponse) {
      return sfResponse;
    }
  } catch (error) {
    console.log('SalesForceService.save() - An error has occurred: ', error);
    return null;
  }
};

export default {
  search,
  save
};
