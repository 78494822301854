import { httpWatson } from '@app/services/request';

const getWatsonToken = async () => {
  try {
    const watsonResponse = await httpWatson.get('/watson/token');

    if (watsonResponse && watsonResponse.data) {
      return watsonResponse.data;
    } else {
      return null;
    }
  } catch (error) {
    console.log('search() - An error has occurred: ', error);
    return null;
  }
};

export default {
  getWatsonToken
};
