import React, { ReactNode } from 'react';
import { Animated, ViewProps } from 'react-native';
import styled, { css } from 'styled-components/native';

import { Handle, HeaderButtons } from '@common/components';
import { colors } from '@common/theme';
import { isAndroid } from '@common/utils';

const Panel = styled(Animated.View)`
  position: absolute;
  width: 100%;
  background: white;
  bottom: -15px;
  box-shadow: 0 -6px 6px rgba(110, 110, 110, 0.1);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  ${isAndroid &&
  css`
    elevation: 4;
    shadow-color: ${colors.black};
  `}
`;

const PanelHeader = styled.View`
  position: absolute;
  top: -34px;
  right: 0;
`;

const PanelContent = styled.View`
  height: 100%;
  padding-top: 0px;
  padding-bottom: 70px;
`;

type ChatPanelProps = {
  children: ReactNode;
  headerButtons?: ButtonsShape;
  onHandlePress?: () => void;
} & Animated.AnimatedProps<ViewProps>;

const ChatPanel = ({ headerButtons, children, onHandlePress, ...props }: ChatPanelProps) => {
  return (
    <Panel {...props}>
      <PanelHeader>
        <HeaderButtons buttons={headerButtons} />
      </PanelHeader>
      <Handle onPress={onHandlePress} />
      <PanelContent>{children}</PanelContent>
    </Panel>
  );
};

export default ChatPanel;
