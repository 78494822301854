import React, { useEffect, useRef, useState } from 'react';
import { Animated, Keyboard } from 'react-native';
import styled from 'styled-components/native';
import { StyledTextInput } from 'styled-components/types';

import { useSelector as useCommonSelector, useStt, useTabBarHeight } from '@common/hooks';
import { boolToNum, cancelConfirmationAlert } from '@common/utils';
import { ActionToolbar } from '@common/components';
import { getUserId } from '@common/store/reducers/user';
import { ChatService, SalesForceService } from '@app/services';
import { useDispatch, useSelector } from '@app/hooks';
import {
  reset,
  selectChat,
  setAccountId,
  setCheckForSearch,
  setCurrentIntent,
  setCurrentMessage,
  setLoading,
  setOptionButtons,
  setResponses,
  setSearchResults,
  setShowActions,
  setShowSearchPanel
} from '@app/store/reducers/chat';

import Messages from './Messages';
import SearchPanel from './SearchPanel';
import ChatPanel from './ChatPanel';
import PicklistPanel from './PicklistPanel';

const ChatSection = styled(Animated.View)`
  width: 100%;
  transition: 0.3s;
  border-radius: 20px;
  justify-content: flex-end;
  position: relative;
  flex: 1;
`;

const MessageSection = styled.View`
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const ToolbarSection = styled.View`
  flex-direction: row;
  justify-content: space-between;
  height: auto;
  position: absolute;
  bottom: 10px;
  margin: 0;
  left: 10px;
  right: 10px;
  width: auto;
`;

const MessagesContainer = styled(Animated.View)``;

const Chat = () => {
  const dispatch = useDispatch();
  const loggedUser = useCommonSelector(getUserId);
  const {
    responses,
    checkForSearch,
    searchResults,
    currentMessage,
    loading,
    currentIntent,
    accountId,
    optionButtons,
    showActions,
    followUpActions,
    showSearchPanel
  } = useSelector(selectChat);
  const { isLoadingStt, startStt, stopStt, transcript } = useStt();
  const inputRef = useRef<StyledTextInput>(null);
  const { animatedTabBarHeight } = useTabBarHeight();
  const animatedShowPanel = useRef(new Animated.Value(0)).current;
  const [isExpanded, setIsExpanded] = useState(false);

  const initialMessage = [
    {
      isBot: true,
      message: "Hi there! I'm Rollio Chat, nice to meet you!",
      date: new Date()
    }
  ];

  useEffect(() => {
    if (!responses.length) {
      dispatch(setResponses(initialMessage));
    }
  }, []);

  useEffect(() => {
    if (responses.length > 0) {
      const lastResponse = responses[responses.length - 1];
      if (!lastResponse.isBot) {
        const message = lastResponse.messageBot || lastResponse.message;
        handleMessageSubmit(message);
      }
    }
  }, [responses.length]);

  useEffect(() => {
    if (transcript) {
      handleMessageChange(transcript);
    }
  }, [transcript]);

  const focusToolbarInput = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  };

  const restartConversation = async (clearHistory = false) => {
    try {
      // Restart conversation on RASA
      await ChatService.restart();

      // Do not clean UI history chat
      if (clearHistory) {
        dispatch(reset());
      }
    } catch (err) {
      console.log('An error occurred while communicating with RASA. Conversation could not be restarted.', err);
    }
  };

  const handleSearchSubmit = async () => {
    dispatch(setLoading(true));
    try {
      const results = await ChatService.search(currentMessage, currentIntent || '');
      dispatch(setShowSearchPanel(true));
      dispatch(setSearchResults(results));
      focusToolbarInput();
    } catch (err) {
      console.log('An error occurred while communicating with RASA', err);
    }
    dispatch(setLoading(false));
  };

  const handleMessageSubmit = async (message: string) => {
    dispatch(setLoading(true));
    try {
      const botResponse = await ChatService.create(message, loggedUser);

      // Get tracker
      const tracker = await ChatService.getTracker(loggedUser);
      console.log('tracker', tracker);

      const formFinished = ChatService.checkIsFinished(tracker, currentIntent);
      if (formFinished) {
        handleFinishedConversation();
      }

      // Set current flow (Intent)
      const intent = await ChatService.getCurrentIntent(tracker);
      if (intent !== currentIntent) {
        dispatch(setAccountId(null));
      }
      dispatch(setCurrentIntent(intent));

      const cachedForm = await ChatService.checkCachedForm(tracker);
      const showFollowUpActions = !intent && !cachedForm;
      dispatch(setShowActions(showFollowUpActions));

      // If the Account was not selected yet
      if (!accountId) {
        const checkSearch = await ChatService.checkForSearch(tracker, intent);
        dispatch(setCheckForSearch(checkSearch));
        if (!checkSearch) {
          dispatch(setShowSearchPanel(false));
        }
      }

      // If there are buttons for option selection
      const responseButtons = botResponse.find(b => b.buttons)?.buttons;
      if (responseButtons) {
        const mappedButtons = responseButtons.map(b => ({
          description: b.title,
          value: b.payload
        }));

        dispatch(setOptionButtons(mappedButtons));
      }

      dispatch(setResponses([...responses, ...botResponse]));
      focusToolbarInput();
    } catch (err) {
      console.log('An error occurred while communicating with RASA', err);
    }
    dispatch(setLoading(false));
  };

  const handleMessageChange = (value: string) => {
    dispatch(setCurrentMessage(value));
  };

  const handleCancel = () => {
    Keyboard.dismiss();
    cancelConfirmationAlert(() => {
      const message = {
        message: 'Cancel',
        isBot: false,
        date: new Date()
      };
      dispatch(setResponses([...responses, message]));
      dispatch(setCurrentMessage(''));
    });
  };

  const handleSubmit = () => {
    const message = {
      message: currentMessage,
      isBot: false,
      date: new Date()
    };
    dispatch(setResponses([...responses, message]));
    dispatch(setCurrentMessage(''));
  };

  const handleSubmitOption = (value: string, payload?: string) => {
    const message = {
      message: value,
      messageBot: payload,
      isBot: false,
      date: new Date()
    };

    dispatch(setResponses([...responses, message]));
    dispatch(setCurrentMessage(''));
    dispatch(setOptionButtons([]));
  };

  const handleSearchTableSelection = (selection: any) => {
    const { key, value } = selection;
    console.info('Account selected:', selection.key);

    dispatch(setAccountId(key));
    dispatch(setCheckForSearch(false));
    dispatch(setShowSearchPanel(false));
    dispatch(setSearchResults([]));

    const message = {
      message: value,
      isBot: false,
      date: new Date()
    };
    dispatch(setResponses([...responses, message]));
    dispatch(setCurrentMessage(''));
  };

  const handleOptionButtonSelection = async (option: OptionButton) => {
    console.info('Option Button selected:', option.description);
    handleSubmitOption(option.description, option.value);
  };

  const onClickFollowUp = async (message: string) => {
    console.info('Follow up selected:', message);
    handleSubmitOption(message);
  };

  const handleFinishedConversation = async () => {
    const result = await SalesForceService.save(loggedUser, currentIntent || '', {
      accountId
    });
    restartConversation();

    console.info('SF saved successfully!', result);
  };

  const showPicklist = !!(optionButtons && optionButtons.length);
  const showSearch = checkForSearch && showSearchPanel;
  const showPanel = showPicklist || showSearch;
  const headerButtons: ButtonsShape = showPanel ? [{ label: 'Cancel', onPress: handleCancel, variant: 'grey' }] : [];

  useEffect(() => {
    Animated.timing(animatedShowPanel, {
      toValue: boolToNum(showPanel),
      duration: 300,
      useNativeDriver: false
    }).start();
  }, [showPanel]);

  const toggleExpansion = () => {
    const value = !isExpanded;
    setIsExpanded(value);

    Animated.timing(animatedShowPanel, {
      toValue: value ? 2 : 1,
      duration: 300,
      useNativeDriver: false
    }).start();
  };

  return (
    <ChatSection style={{ marginBottom: animatedTabBarHeight }}>
      <MessageSection>
        <MessagesContainer
          style={{
            height: animatedShowPanel.interpolate({
              inputRange: [0, 1, 2],
              outputRange: ['100%', '60%', '20%']
            })
          }}
        >
          <Messages
            messages={responses}
            loadingStatus={loading}
            showFollowUp={showActions}
            followUpActions={followUpActions}
            onClick={onClickFollowUp}
            showPanel={showPanel}
          />
        </MessagesContainer>
      </MessageSection>
      <ChatPanel
        headerButtons={headerButtons}
        onHandlePress={toggleExpansion}
        style={{
          height: animatedShowPanel.interpolate({
            inputRange: [0, 1, 2],
            outputRange: ['0%', '40%', '80%']
          })
        }}
      >
        {showSearch && <SearchPanel data={searchResults} onClick={handleSearchTableSelection} onRefineSearch={handleSearchSubmit} />}
        {showPicklist && <PicklistPanel data={optionButtons} onClick={handleOptionButtonSelection} />}
      </ChatPanel>
      <ToolbarSection>
        <ActionToolbar
          inputProps={{ ref: inputRef }}
          inputValue={currentMessage}
          inputPlaceholder="What can I help you with?"
          onInputChange={handleMessageChange}
          onSendPress={handleSubmit}
          onSearchPress={handleSearchSubmit}
          disableSendButton={loading}
          disableMicButton={loading || isLoadingStt}
          onMicPress={startStt}
          onStopPress={stopStt}
        />
      </ToolbarSection>
    </ChatSection>
  );
};

export default Chat;
