import React from 'react';

import { ChatBubble } from '@common/components';

type MessageProps = {
  message: ChatMessage;
  isLatest?: boolean;
};

const Message = ({ message, isLatest }: MessageProps) => {
  return <ChatBubble isBot={message.isBot} createdAt={message.date} text={message.message} showAvatar={isLatest} />;
};

export default Message;
