import React, { lazy, Suspense } from 'react';
import { Text } from 'react-native';

import { useSelector } from '@common/hooks';
import { selectIsLegacy } from '@common/store/reducers/auth';

const AppNavigator = lazy(async () => import('@app/App'));
const LegacyNavigator = lazy(async () => import('@legacy/App'));

const MainStack = () => {
  const isLegacy = useSelector(selectIsLegacy);
  const StackNavigator = isLegacy ? LegacyNavigator : AppNavigator;

  return (
    <Suspense fallback={<Text>Loading...</Text>}>
      <StackNavigator />
    </Suspense>
  );
};

export default MainStack;
