import React from 'react';
import styled from 'styled-components/native';

import { Button } from '@common/components';

const ButtonContainer = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px;
`;

const StyledButton = styled(Button)`
  margin: 0 10px 10px 0;
`;

type PicklistPanelProps = {
  data: OptionButtons;
  onClick: (option: OptionButton) => void;
};

const PicklistPanel = ({ data, onClick }: PicklistPanelProps) => {
  return (
    <>
      <ButtonContainer>
        {data.map((opt, idx) => (
          <StyledButton key={idx} onPress={() => onClick(opt)} variant="grey">
            {opt.description}
          </StyledButton>
        ))}
      </ButtonContainer>
    </>
  );
};

export default PicklistPanel;
