import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';

import {
  // WalkthroughScreen,
  // WebViewScreen,
  ProfileScreen,
  StorybookScreen
} from '@legacy/app/layouts';
import {
  // WEBVIEW_SCREEN,
  PROFILE_SCREEN,
  // WALKTHROUGH_SCREEN,
  STORYBOOK_SCREEN
} from '@legacy/app/navigation/routes';

const Stack = createStackNavigator();

const ProfileStack = () => (
  <Stack.Navigator initialRouteName={PROFILE_SCREEN} screenOptions={{ headerShown: false }}>
    <Stack.Screen name={PROFILE_SCREEN} component={ProfileScreen} />
    {/* <Stack.Screen name={WALKTHROUGH_SCREEN} component={WalkthroughScreen} /> */}
    {/* <Stack.Screen name={WEBVIEW_SCREEN} component={WebViewScreen} /> */}
    {__DEV__ && <Stack.Screen name={STORYBOOK_SCREEN} component={StorybookScreen} />}
  </Stack.Navigator>
);

export default ProfileStack;
