import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Animated } from 'react-native';
import styled, { css } from 'styled-components/native';
import { StyledTouchableOpacityProps } from 'styled-components/types';

import { colors } from '@common/theme';
import { isSpeechSupported } from '@common/utils';
import { Icon, TouchableWithEvent } from '@common/components';

import { isSearch, isDefault } from '../shared';

type MainButtonProps = {
  disabled?: boolean;
  isInputPopulated: boolean;
  isMicActive: boolean;
  onPress: () => void;
  variant: ToolbarVariants;
};

const ToolbarButtonWrap = styled.View`
  align-self: flex-end;
  z-index: 1;
`;

const ButtonGlow = styled.View`
  position: absolute;
  left: 0;
  top: 0;
  width: 36px;
  height: 36px;
  border-radius: 18px;
  background: ${colors.red2};
  transform: scale(1.5);
`;

type ToolbarButtonProps = {
  isMicActive: boolean;
};

const ToolbarButton = styled.TouchableOpacity.attrs<void, StyledTouchableOpacityProps>({
  activeOpacity: 0.8
})<ToolbarButtonProps>(
  ({ isMicActive, disabled }) => css`
    width: 36px;
    height: 36px;
    border-radius: 18px;
    background: ${colors[!isMicActive ? 'primary' : 'red2']};
    align-items: center;
    justify-content: center;
    opacity: ${disabled ? 0.8 : 1};
  `
);

const getToolbarButtonIcon = (variant: ToolbarVariants, isMicActive?: boolean, isPopulated?: boolean): string => {
  if (isMicActive) {
    return 'stop';
  }

  if (isPopulated || !isSpeechSupported) {
    return isSearch(variant) ? 'search' : 'send';
  }

  return 'mic';
};

const getTrackEventId = (buttonIcon: string) => {
  switch (buttonIcon) {
    case 'mic':
      return 'chat_mic_start_click';
    case 'stop':
      return 'chat_mic_stop_click';
    case 'search':
      return 'chat_search_click';
    case 'send':
    default:
      return 'chat_send_click';
  }
};

const GLOW_INITIAL_OPACITY = 0.5;
const GLOW_INITIAL_SIZE = 0;

const createGlowSequence = (size: Animated.AnimatedValue, opacity: Animated.AnimatedValue, offset: number = 0) =>
  Animated.sequence([
    Animated.parallel([
      Animated.timing(size, {
        toValue: 1,
        duration: 1700,
        delay: offset,
        useNativeDriver: true
      }),
      Animated.timing(opacity, {
        toValue: 0,
        duration: 1000,
        delay: 700 + offset,
        useNativeDriver: true
      })
    ]),
    Animated.parallel([
      Animated.timing(size, {
        toValue: GLOW_INITIAL_SIZE,
        duration: 0,
        useNativeDriver: true
      }),
      Animated.timing(opacity, {
        toValue: GLOW_INITIAL_OPACITY,
        duration: 0,
        useNativeDriver: true
      })
    ])
  ]);

type GlowingWrapProps = {
  opacity: Animated.AnimatedValue;
  size: Animated.AnimatedValue;
};

const GlowingWrap = ({ opacity, size }: GlowingWrapProps) => (
  <ButtonGlow
    as={Animated.View}
    style={{
      transform: [
        {
          scale: size.interpolate({
            inputRange: [0, 1],
            outputRange: [0.9, 1.6]
          })
        }
      ],
      opacity
    }}
  />
);

const MainButton = ({ disabled, isInputPopulated, isMicActive, onPress, variant }: MainButtonProps) => {
  const [animatedGlowSize] = useState(new Animated.Value(GLOW_INITIAL_SIZE));
  const [animatedGlowOpacity] = useState(new Animated.Value(GLOW_INITIAL_OPACITY));
  const [animatedGlowSize2] = useState(new Animated.Value(GLOW_INITIAL_SIZE));
  const [animatedGlowOpacity2] = useState(new Animated.Value(GLOW_INITIAL_OPACITY));
  const [animatedGlowSize3] = useState(new Animated.Value(GLOW_INITIAL_SIZE));
  const [animatedGlowOpacity3] = useState(new Animated.Value(GLOW_INITIAL_OPACITY));

  const buttonIcon = useMemo(() => getToolbarButtonIcon(variant, isMicActive, isInputPopulated), [isInputPopulated, isMicActive, variant]);

  const trackEventId = useMemo(() => getTrackEventId(buttonIcon), [buttonIcon]);

  const startGlow = useCallback(() => {
    const delay = 700;
    Animated.loop(
      Animated.parallel([
        createGlowSequence(animatedGlowSize, animatedGlowOpacity),
        createGlowSequence(animatedGlowSize2, animatedGlowOpacity2, delay),
        createGlowSequence(animatedGlowSize3, animatedGlowOpacity3, delay * 2)
      ])
    ).start();
  }, [animatedGlowSize, animatedGlowSize2, animatedGlowSize3, animatedGlowOpacity, animatedGlowOpacity2, animatedGlowOpacity3]);

  useEffect(() => {
    if (isMicActive) {
      startGlow();
    }
  }, [isMicActive, startGlow]);

  const buttonProps = useMemo(
    () => ({
      disabled: (isDefault(variant) && !isInputPopulated && !isSpeechSupported) || disabled,
      onPress,
      isMicActive,
      as: TouchableWithEvent,
      trackEventId
    }),
    [disabled, isInputPopulated, isMicActive, onPress, variant, trackEventId]
  );

  return (
    <ToolbarButtonWrap>
      {isMicActive && (
        <>
          <GlowingWrap opacity={animatedGlowOpacity} size={animatedGlowSize} />
          <GlowingWrap opacity={animatedGlowOpacity2} size={animatedGlowSize2} />
          <GlowingWrap opacity={animatedGlowOpacity3} size={animatedGlowSize3} />
        </>
      )}

      <ToolbarButton {...buttonProps}>
        <Icon color={colors.white} size={22} name={buttonIcon} />
      </ToolbarButton>
    </ToolbarButtonWrap>
  );
};

export default MainButton;
