import React from 'react';
import { toast, Slide } from 'react-toastify';
import styled from 'styled-components/native';

import { colors, fonts } from '@common/theme';

const ToastText = styled.Text`
  display: block;
  font-size: 14px;
  font-family: ${fonts.medium};
  color: ${colors.white};
  text-align: center;
`;

const Toast = (text: string) =>
  toast(<ToastText>{text}</ToastText>, {
    position: 'bottom-center',
    autoClose: 4000,
    pauseOnHover: true,
    transition: Slide,
    hideProgressBar: true,
    type: toast.TYPE.ERROR
  });

export default Toast;
