import React, { ReactElement, useEffect, useState } from 'react';
import { Animated, Dimensions, ImageSourcePropType } from 'react-native';
import styled from 'styled-components/native';

import { colors, fonts } from '@common/theme';
import { isIphoneX, statusBarHeight } from '@common/utils';
import { Button } from '@common/components';

type SliderStepProps = {
  activePage?: number;
  animatedActivePage: Animated.AnimatedDivision;
  description: Array<string | ReactElement>;
  image: ImageSourcePropType;
  lastPage?: number;
  onContinuePress?: () => void;
  page: number;
  showContinueButton?: boolean;
  title: string;
};

const deviceWidth = Dimensions.get('window').width;
const topPadding = isIphoneX ? 30 : 10;

const WalkthroughStep = styled.View`
  flex: 1;
  width: ${deviceWidth}px;
  padding-top: ${statusBarHeight + topPadding}px;
  align-items: center;
`;

const StepImage = styled.Image`
  width: 219px;
  height: ${isIphoneX ? '440px' : '400px'};
  resize-mode: contain;
`;

const StepDetails = styled.View`
  margin-top: ${isIphoneX ? '72px' : '52px'};
`;

const StepTitle = styled.Text`
  color: ${colors.grey4};
  font-size: 24px;
  font-family: ${fonts.bold};
  text-align: center;
  margin-bottom: 12px;
`;

const StepDescription = styled.Text`
  color: ${colors.grey4};
  font-size: 16px;
  font-family: ${fonts.medium};
  text-align: center;
  line-height: 20px;
  margin-bottom: 4px;
  padding: 0 42px;
`;

const ButtonWrap = styled.View`
  margin-top: ${isIphoneX ? '32px' : '20px'};
  align-items: center;
`;

const SliderStep = ({ activePage, animatedActivePage, description, image, lastPage, onContinuePress, page, showContinueButton, title }: SliderStepProps) => {
  const [animatedButtonVisibility] = useState(new Animated.Value(0));

  useEffect(() => {
    if ([activePage, lastPage].includes(undefined)) {
      return;
    }

    if (activePage === lastPage) {
      Animated.spring(animatedButtonVisibility, {
        toValue: 1,
        // duration: 300,
        bounciness: 12,
        useNativeDriver: true
      }).start();
    }
  }, [activePage, animatedButtonVisibility, lastPage]);

  return (
    <WalkthroughStep>
      <StepImage
        as={Animated.Image}
        source={image}
        style={{
          transform: [
            {
              scale: animatedActivePage.interpolate({
                inputRange: [page - 1, page, page + 1],
                outputRange: [0.75, 1, 0.75],
                extrapolate: 'clamp'
              })
            },
            {
              translateY: animatedActivePage.interpolate({
                inputRange: [page - 1, page, page + 1],
                outputRange: [-50, 0, -50],
                extrapolate: 'clamp'
              })
            }
          ]
        }}
      />
      <StepDetails
        as={Animated.View}
        style={{
          opacity: animatedActivePage.interpolate({
            inputRange: [page - 0.5, page, page + 0.5],
            outputRange: [0, 1, 0],
            extrapolate: 'clamp'
          }),
          transform: [
            {
              translateY: animatedActivePage.interpolate({
                inputRange: [page - 1, page, page + 1],
                outputRange: [10, 0, 10],
                extrapolate: 'clamp'
              })
            }
          ]
        }}
      >
        <StepTitle>{title}</StepTitle>

        {description.map((line, index) => (
          <StepDescription key={index}>{line}</StepDescription>
        ))}

        {showContinueButton && (
          <ButtonWrap
            as={Animated.View}
            style={{
              opacity: animatedButtonVisibility,
              transform: [
                {
                  translateY: animatedButtonVisibility.interpolate({
                    inputRange: [0, 1],
                    outputRange: [20, 0]
                  })
                }
              ]
            }}
          >
            <Button onPress={onContinuePress}>Continue</Button>
          </ButtonWrap>
        )}
      </StepDetails>
    </WalkthroughStep>
  );
};

export default SliderStep;
